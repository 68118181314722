.fs-lg {
  font-size: $font-size-lg;
}

.fs-sm {
  font-size: $font-size-sm;
}

.fw-medium {
  font-weight: $font-weight-medium;
}