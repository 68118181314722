// Common style for patient and admin;
:root {
  font-size: calc(1em * (var(--ayd-font-scale, 100) / 100));
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-width: 0;
  padding: 0;
  margin: 0;
}

img {
  vertical-align: middle;
  border-style: none; // Remove the border on images inside links in IE 10-.
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  height: 100%;
  text-size-adjust: none;
}

body,
html {
  height: 100%;
}

/* stylelint-disable-next-line selector-id-pattern */
#__next {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;

  .textarea-without-borders {
    background-color: transparent !important;
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
    resize: none;
    padding: 0;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    appearance: textfield;
  }

  .page-overlay {
    position: fixed;
    inset: 0;
    background-color: var(--bs-body-bg);
    z-index: 10;
  }

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .react-select-container .react-select {
    &__menu {
      z-index: 5;
    }

    &__control {
      min-height: auto;
      border: 1px solid var(--bs-border-color) !important;
      border-radius: var(--bs-border-radius) !important;

      &--is-disabled {
        color: $input-disabled-color;
        background-color: $input-disabled-bg;
        border-color: $input-disabled-border-color;

        // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
        opacity: 1;
      }
    }

    &__multi-value {
      margin-top: 0;
      margin-bottom: 0;

      &__label {
        padding: 1px 3px;
      }
    }

    &__value-container {
      padding: 0.25rem 0.7rem;
      font-size: 0.825rem;
      font-weight: 400;
      line-height: 1.625;
      color: $static-gray-700;
    }

    &__input-container {
      margin: 0;
      padding: 0;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      padding: 0 8px;
    }
  }

  .react-table {
    &__empty {
      font-size: 12px;
      text-align: center;
      padding: 1rem;

      &--loading {
        opacity: 0;
      }
    }

    &__pagination {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 0.75rem 1.25rem;
      justify-content: flex-end;
    }
  }

  .internal-note-block {
    &:hover {
      .internal-note-block__tools {
        display: flex;
      }
    }

    &__tools {
      display: none;
      flex-direction: row;
      position: absolute;
      top: 10px;
      right: 10px;
      gap: 6px;

      &_item {
        background: white;
        border-radius: 6px;
        padding: 3px 7px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.custom-date-picker-links-container {
  display: inline-block;
}

.custom-date-picker-links-item {
  font-size: 11px;
  margin-right: 7px;
}

*[data-tip] {
  cursor: pointer;
}

.react-datepicker__day--outside-month {
  opacity: 0.5 !important;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.multi-date-select-clear-button {
  right: 5px !important;
}

.my-account-page-avatar {
  width: 100px;
  height: 100px;
  background-color: silver;
  border-radius: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &:hover,
  &--uploading {
    .my-account-page-avatar__upload-label {
      display: flex;
    }
  }

  &__upload-label {
    background-color: rgb(255 255 255 / 80%);
    color: var(--bs-body-color);
    cursor: pointer;
    height: 100%;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    display: none;
    align-items: center;
    justify-content: center;
  }
}

.case-report-details {
  &__order {
    &_status {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
      }
    }
  }
}

.redux-toastr {
  .toastr {
    opacity: 1;

    .close-toastr {
      opacity: 1;
      color: white;
    }
  }

  .toastr:hover:not(.rrt-message) {
    box-shadow: 2px 2px 10px rgb(0 0 0 / 40%);
  }
}

.needs-attention-indicator {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: $blue;
  display: inline-block;
}

.flag {
  width: 12px;
  height: 12px;
  display: inline-block;
}

.comparison-indicator {
  position: absolute;
  top: 50%;
  margin-top: -7px;
  right: -40px;

  &--up-green {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11px 18px;
    border-color: transparent transparent $green;
  }

  &--up-red {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11px 18px;
    border-color: transparent transparent $red;
  }

  &--down-green {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 11px 0;
    border-color: $green transparent transparent;
  }

  &--down-red {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 11px 0;
    border-color: $red transparent transparent;
  }

  &--same {
    width: 20px;
    height: 20px;
    background-color: $static-gray-500;
    border-radius: 10px;
  }
}

.no-overflow {
  overflow: hidden;
}

.employee-search-select-container {
  min-width: 280px;
}

/* Customize the label (the container) */
.custom-radio-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 10px;
}

/* Hide the browser's default radio button */
.custom-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.custom-checkmark {
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.custom-radio-container:hover input ~ .custom-checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.custom-radio-container input:checked ~ .custom-checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-checkmark::after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio-container input:checked ~ .custom-checkmark::after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio-container .custom-checkmark::after {
  top: 10px;
  left: 10px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  border-radius: 50%;
  background: white;
}

.ReactModal__Overlay--after-open {
  z-index: 1900 !important;
}

.react-draggable {
  &.modal-dialog {
    transition-duration: 0s !important;
  }

  .modal-header {
    cursor: move;

    &::after {
      opacity: 0.3;
      background-image: url('/img/icons/move.svg');
      background-size: 30px 30px;
      content: ' ';
      height: 30px;
      width: 30px;
    }
  }
}

.custom-nav-tabs {
  display: flex;
  flex-direction: row;
  position: relative;

  &::after {
    content: '';
    height: 1px;
    background-color: #ced4da;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &--card-layout {
    &::after {
      display: none;
    }

    .custom-nav-tabs__item {
      border-bottom: none;

      &--active {
        background-color: white;
      }
    }
  }

  &__item {
    cursor: pointer;
    border-bottom: 2px solid transparent;
    position: relative;
    z-index: 1;

    &--active {
      border-bottom-color: $static-gray-700;

      .nav-link {
        cursor: default;

        &:hover {
          color: $tab-link-color !important;
        }
      }
    }

    &--disabled {
      opacity: 0.4;

      .nav-link {
        cursor: default;

        &:hover {
          color: $tab-link-color !important;
        }
      }
    }
  }
}

.custom-chat {
  position: absolute;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  inset: 0;
  justify-content: space-between;

  &__open-ticket {
    &_avatar {
      width: 40px;
      height: 40px;
      background-color: $input-placeholder-color;
      background-size: cover;
      background-position: center;
    }
  }

  &__tools-dropdown {
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }

  &__messages {
    overflow-x: hidden;
    max-height: none;
    flex: 1;
    padding-top: 0 !important;
    display: flex;
    flex-direction: column-reverse;
  }

  &__top-loader {
    height: 44px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .infinite-scroll-component__outerdiv {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
  }

  &__infinite-scroller {
    gap: 1.5rem;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden !important;
    padding-top: 0 !important;

    /* safari bug */
    &::after {
      content: 'content';
      height: 0;
      opacity: 0;
    }
  }

  &__day {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    &::before,
    &::after {
      content: '';
      height: 1px;
      flex: 1;
      background-color: var(--bs-border-color);
    }
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  &__compose-form {
    &_attach-button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 32px;
      cursor: pointer;
    }

    &_attached-image {
      width: 48px;
      height: 48px;
      background-size: cover;
      background-position: center;
    }

    &_text-templates-dropdown {
      width: 32px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .dropdown-toggle {
        &::after {
          display: none;
        }
      }

      &-tooltip {
        .tooltip-inner {
          max-width: 300px;
          white-space: pre-wrap;
          text-align: left;
        }
      }
    }

    &_attached-file {
      display: flex;
      gap: 0.5rem;

      &-title {
        white-space: pre-wrap;
        word-break: break-word;
      }

      &-close-button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 20px;
        height: 20px;
        background: $static-gray-700;
        border-radius: 50%;
        top: -5px;
        right: -5px;

        &:hover {
          background: $static-gray-900;
        }
      }

      a {
        font-size: 12px;
      }
    }

    &_attached-files {
      display: flex;
      padding-left: 31px;
      padding-bottom: 0.5rem;
    }
  }

  &__loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__media-items {
    display: flex;
    flex-direction: row;
    gap: 5px;

    &_doc-or-video {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.5rem;
      background: $white;

      &-info {
        font-size: 12px;
      }

      &-title {
        white-space: pre-wrap;
        word-break: break-word;
      }
    }
  }

  &__viewed-info-icon {
    position: absolute;
    right: -8px;
    top: -1px;

    img {
      height: 7px;
    }
  }

  &__message-context-menu {
    position: absolute;
    visibility: hidden;
    z-index: 99;

    &_item {
      padding: 0.5rem 1rem;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background-color: var(--bs-light);
      }
    }
  }
}

.chat-message-left {
  .highlighted {
    border-left: 5px solid var(--bs-primary-border-subtle);
  }
}

.chat-message-right {
  .highlighted {
    border-right: 5px solid var(--bs-primary-border-subtle);
  }
}

// Allow scroll when modal opened
.modal-open {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.callout {
  border: 1px solid var(--bs-border-color);
  border-left-width: 5px;
}

@each $color, $value in $theme-colors {
  .callout-#{$color} {
    border-left-color: var(--bs-#{$color});
  }

  .callout-#{$color}-subtle {
    border-left-color: var(--bs-#{$color}-border-subtle);
  }
}

@each $color, $value in $theme-colors {
  .modal-header-#{$color} {
    border-top: 10px solid var(--bs-#{$color});
  }
}
